import React, { FC } from "react"
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab"
import { Typography } from "@material-ui/core"

export interface HistoryItem {
  year: string
  text: string
}

const HistoryTimelineItem: FC<HistoryItem> = ({ year, text }) => {
  const renderYear = () => {
    return (
      <TimelineOppositeContent>
        <Typography color="textSecondary">{year}</Typography>
      </TimelineOppositeContent>
    )
  }

  const renderSeparator = () => {
    return (
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
    )
  }

  const renderText = () => {
    return (
      <TimelineContent>
        <Typography>{text}</Typography>
      </TimelineContent>
    )
  }

  return (
    <TimelineItem>
      {renderYear()}
      {renderSeparator()}
      {renderText()}
    </TimelineItem>
  )
}

export default HistoryTimelineItem
