import React, { FC } from "react"
import { PageProps } from "gatsby"
import { Typography } from "@material-ui/core"
import { Timeline } from "@material-ui/lab"
import HistoryTimelineItem, {
  HistoryItem,
} from "../components/historyTimelineItem"
import Layout from "../components/layout"

const history: HistoryItem[] = [
  { year: "1996", text: "Gründung Camerata Andechs" },
  {
    year: "1997",
    text:
      "Gründung des Fördervereins Camerata Andechs e. V. (Sitz: Starnberg), Anerkennung der Gemeinnützigkeit",
  },
  {
    year: "1998",
    text: 'Umbenennung in "Orchester- und Förderverein Camerata Andechs e. V."',
  },
  {
    year: "1999",
    text: 'Gründung "Symphonisches Orchester München-Andechs" (SOMA)',
  },
  {
    year: "1999",
    text: 'Umbenennung "Orchestergemeinschaft München-Andechs e. V." (OGeMA)',
  },
  { year: "2003", text: 'Gründung der "Herbstakademie der OGeMA"' },
]

const ChronikPage: FC<PageProps> = () => {
  function renderChronikTitle() {
    return (
      <Typography variant="h4" paragraph>
        Chronik
      </Typography>
    )
  }

  function renderTimeline() {
    return (
      <Timeline align="alternate">
        {history.map(item => (
          <HistoryTimelineItem year={item.year} text={item.text} />
        ))}
      </Timeline>
    )
  }

  function renderText() {
    return (
      <Typography>
        Am Beginn unserer Orchesterarbeit standen 1996 zwei große sinfonische
        Konzerte (Händel, Mozart, Dvorak) unter Anton Ludwig Pfell mit der
        jungen, damals 14-jährigen Solistin Arabella Steinbacher, Violine.
        Veranstalter waren der Rotaract Club Starnberg (Präsident Dr. Hubertus
        Glaser) und der Rotary Club Weilheim (Präsident Uwe Richardsen). Schon
        damals konnte ein Benefiz von 3500 DM zugunsten des SOS Jugendhauses
        Weilheim erspielt werden.
        <br /> Inzwischen finden jährlich etwa 15 Konzerte in verschiedenster
        Besetzung statt an welchen sich stets ca. hundert Musiker (Studierende
        und Erwachsene, Profis und Laien) beteiligen.
      </Typography>
    )
  }

  return (
    <Layout>
      {renderChronikTitle()}
      {renderTimeline()}
      {renderText()}
    </Layout>
  )
}

export default ChronikPage
